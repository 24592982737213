import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom"
import styled from "styled-components"

import VerticalHeader from "./components/Header/VerticalHeader"
import { PdfPreview } from "./components/PdfPreview"
import colors from "./designSystem/colors"
import { Web3Provider } from "./hooks/Web3Provider"

import "./index.css"

import NotFound from "./pages/NotFound"
import VaultPage from "./pages/Vault"
import VaultsPage from "./pages/Vaults"
import { ThreeJaneProvider } from "./store"

const Body = styled.div`
  background-color: ${colors.background.one};
  display: flex;
  height: 100vh;
  overflow: hidden;
`

function App() {
  return (
    <Web3Provider>
      <Body>
        <BrowserRouter>
          <HeaderAndRoutes />
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </BrowserRouter>
      </Body>
    </Web3Provider>
  )
}

function HeaderAndRoutes() {
  const location = useLocation()
  const hideHeaderRoutes = ["/policy", "/whitepaper", "/terms", "/disclaimer"]

  return (
    <ThreeJaneProvider>
      {!hideHeaderRoutes.includes(location.pathname) && <VerticalHeader />}
      <Routes>
        <Route path="/" element={<VaultsPage />} />
        <Route path="/policy" element={<PdfPreview src="/pdf/policy.pdf" />} />
        <Route
          path="/terms"
          element={<PdfPreview src="/pdf/terms-of-service.pdf" />}
        />
        <Route
          path="/disclaimer"
          element={<PdfPreview src="/pdf/disclaimer.pdf" />}
        />
        <Route path="/vault/:pool" element={<VaultPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ThreeJaneProvider>
  )
}

export default App
